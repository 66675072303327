<template>
  <div>
    <!--Progress Bar-->
    <v-dialog
      v-model="progress"
      max-width="290"
      persistent
      hide-overlay
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-center">
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container
      v-if="progress !== true"
      fluid
      grid-list-md
    >
      <!--Title-->
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <div class="display-1 secondary--text">
            API Documentation
          </div>
        </v-flex>
      </v-layout>

      <!--API Authentication-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Authentication
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">URL Endpont:</span> https://portal.idexint.com/oauth/token</p>
              <p><span class="font-weight-bold secondary--text">Method:</span> POST</p>
              <p><span class="font-weight-bold secondary--text">Header:</span><br>Content-Type: application/json</p>
              <span class="font-weight-bold secondary--text">Parameters:</span>
              <ul>
                <li>client_id <span class="error--text"> - string </span><span class="font-italic error--text">(provided by iDex)</span></li>
                <li>client_secret <span class="error--text"> - string </span><span class="font-italic error--text">(provided by iDex)</span></li>
                <li>grant_type <span class="error--text"> - string </span><span class="font-italic error--text">("password" every request)</span></li>
                <li>password <span class="error--text"> - string </span><span class="font-italic error--text">(password associated with portal login)</span></li>
                <li>username <span class="error--text"> - string </span><span class="font-italic error--text">(email address associated with portal login)</span></li>
              </ul>
              <p><span class="font-weight-bold secondary--text">Response:</span><br>access_token</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Example Request</span>
              <pre>{{ jsonAuth | pretty }}</pre>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Shipping Carriers-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Shipping Carriers
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">Usage:</span> If the Order request includes "ship = 1" (Ship Order), the "shippingCarrierID" value is required to use the ID listed for the specific shipping carrier.</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Shipping Carrier Values</span>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in shippingCarriers"
                      :key="item.name"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Shipping Carriers Methods-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Shipping Carrier Methods
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">Usage:</span> If the Order request includes "ship = 1" (Ship Order), the "shippingMethodID" value is required to use the ID listed for the specific shipping carrier method.</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Shipping Carrier Method Values</span>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Method
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in shippingMethods"
                      :key="item.name"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Decorating Locations-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Decorating Locations
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">Usage:</span> In the order request use one of the ID's listed for "imageLocationID" at the "line_items" -> "images level".</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Decorating Location Values</span>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in designLocationsStore.designLocations"
                      :key="item.id"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.d_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Services-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Services
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">Usage:</span> In the order request use one of the ID's listed for "itemServiceID" at the "line_items" -> "images level".  Service ID's are specific to the order "type", so use only services that match the "type" in the order request.</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Service Values</span>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Order Type
                      </th>
                      <th class="text-left">
                        Service
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in servicesStore.services"
                      :key="item.id"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ translateOrderType(item.f_orderType) }}</td>
                      <td>{{ item.d_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Decorating Options-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Decorating Options
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">Usage:</span> In the order request use one or many of the ID's listed for "itemOptions" at the "line_items" -> "images level". Option ID's are specific to the order "type", so use only options that match the "type" in the order request.</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Decorating Option Values</span>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Order Type
                      </th>
                      <th class="text-left">
                        Option
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in servicesStore.options"
                      :key="item.id"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ translateOrderType(item.f_orderType) }}</td>
                      <td>{{ item.d_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!--Order-->
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey lighten-5"
            height="100%"
          >
            <v-card-title
              primary
              class="headline font-weight-bold primary--text"
            >
              Submit Order(s)
            </v-card-title>
            <v-card-text class="subtitle-1">
              <p><span class="font-weight-bold secondary--text">URL Endpont:</span> https://portal.idexint.com/api/orders/batch</p>
              <p><span class="font-weight-bold secondary--text">Method:</span> POST</p>
              <p><span class="font-weight-bold secondary--text">Header:</span><br>Content-Type: application/json<br>Authorization: Bearer <span class="error--text">access_token </span><span class="font-italic error--text">(returned from authentication call)</span></p>
              <span class="font-weight-bold secondary--text">Parameters:</span>
              <ul>
                <li>
                  items <span class="error--text"> - array</span>
                  <ul>
                    <li>customerPurchaseOrder <span class="error--text"> - string</span></li>
                    <li>dateDue <span class="error--text"> - date </span><span class="font-italic error--text">(YYYY-MM-DD)</span></li>
                    <li>jobName <span class="error--text"> - string</span></li>
                    <li>design <span class="error--text"> - integer - 1 or 2 </span><span class="font-italic error--text">(Design Supplied = 1, Design Needs Digitizing = 2)</span></li>
                    <li>new <span class="error--text"> - integer - 1 or 2 </span><span class="font-italic error--text">(New Design = 1, Repeat Design = 2)</span></li>
                    <li>ship <span class="error--text"> - integer - 1 or 2 </span><span class="font-italic error--text">(Ship Order = 1, Pick Up Order = 2)</span></li>
                    <li>priority <span class="error--text"> - integer - 1 </span><span class="font-italic error--text">(Rush Order = 1)</span></li>
                    <li>splitShipment <span class="error--text"> - integer - 1 </span><span class="font-italic error--text">(Yes = 1)</span></li>
                    <li>type <span class="error--text"> - integer - 1 or 2 </span><span class="font-italic error--text">(Embroidery = 1, Screen Printing = 2)</span></li>
                    <li>customerID <span class="error--text"> - string </span><span class="font-italic error--text">(provided by iDex)</span></li>
                    <li>shippingCarrierID <span class="error--text"> - integer - 1, 2, 3, 4 </span><span class="font-italic error--text">(see Shipping Carriers above)</span></li>
                    <li>shippingMethodID <span class="error--text"> - integer - 1, 2, 3 </span><span class="font-italic error--text">(see )</span></li>
                    <li>
                      line_items <span class="error--text"> - array </span>
                      <ul>
                        <li>color <span class="error--text"> - string </span></li>
                        <li>description <span class="error--text"> - string </span></li>
                        <li>productItemNumber <span class="error--text"> - string </span></li>
                        <li>productStyle <span class="error--text"> - string </span></li>
                        <li>quantity <span class="error--text"> - integer </span></li>
                        <li>sizeLG <span class="error--text"> - integer </span></li>
                        <li>sizeMD <span class="error--text"> - integer </span></li>
                        <li>sizeOSFA <span class="error--text"> - integer </span></li>
                        <li>sizeOther <span class="error--text"> - integer </span></li>
                        <li>sizeSM <span class="error--text"> - integer </span></li>
                        <li>sizeXL <span class="error--text"> - integer </span></li>
                        <li>sizeXS <span class="error--text"> - integer </span></li>
                        <li>sizeXXL <span class="error--text"> - integer </span></li>
                        <li>sizeXXXL <span class="error--text"> - integer </span></li>
                        <li>size4XL <span class="error--text"> - integer </span></li>
                        <li>size5XL <span class="error--text"> - integer </span></li>
                        <li>
                          images <span class="error--text"> - array </span>
                          <ul>
                            <li>imageLocationID <span class="error--text"> - integer </span></li>
                            <li>itemOptions <span class="error--text"> - array </span></li>
                            <li>itemServiceID <span class="error--text"> - integer </span></li>
                            <li>instructions <span class="error--text"> - string </span></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card
            flat
            color="grey darken-3"
            height="100%"
          >
            <v-card-text class="subtitle-1 white--text">
              <span>Example Request</span>
              <pre>{{ jsonOrder | pretty }}</pre>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Documentation',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      servicesStore: (state) => state.servicesStore,
      designLocationsStore: (state) => state.designLocationsStore,
    }),
  },
  filters: {
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  data() {
    return {
      progress: true,
      jsonAuth: '{"client_id": "akdhadhasljdhkqwwbeiaysgckjq","client_secret":"qwdlajdlkaiuejldhlfasadawqd","grant_type":"password","password":"xasdsxzff","username":"test@test.com"}',
      jsonOrder: '{"items":[{"customerPurchaseOrder":"888888","dateDue":"2019-10-23","jobName":"Test Order","design":1,"new":2,"ship":2,"priority":0,"splitShipment":0,"type":1,"customerID":"akdhadhasljdhkqwwbeiaysgckjq","shippingCarrierID":1,"shippingMethodID":2,"line_items":[{"color":"White","description":"T-Shirt","productItemNumber":"Shirts","productStyle":"G500","quantity":4,"sizeLG":0,"sizeMD":0,"sizeOSFA":0,"sizeOther":4,"sizeSM":0,"sizeXL":0,"sizeXS":0,"sizeXXL":0,"sizeXXXL":0,"size4XL":0,"size5XL":0,"images":[{"imageLocationID":1,"itemOptions":[6,55],"itemServiceID":3,"instructions":"Navy Thread"}]}]}]}',
      shippingCarriers: [
        {
        	id: 1,
          name: 'UPS',
        },
	      {
		      id: 2,
		      name: 'FedEx',
	      },
	      {
		      id: 3,
		      name: 'USPS',
	      },
	      {
		      id: 4,
		      name: 'Courier',
	      },
      ],
      shippingMethods: [
		    {
			    id: 1,
			    name: 'Ground',
		    },
		    {
			    id: 2,
			    name: '2nd Day',
		    },
		    {
			    id: 3,
			    name: 'Next Day Air',
		    },
	    ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const locations = this.$store.getters['designLocationsStore/getLocations'];
		  if (locations.length === 0) {
		    await this.$store.dispatch('designLocationsStore/fetch');
      }

      await this.$store.dispatch('servicesStore/fetchServices');

      await this.$store.dispatch('servicesStore/fetchAllOptions');

      this.progress = false;
    },

    translateOrderType(value) {
      if (value === 1) {
        return 'Embroidery';
      }
      if (value === 2) {
        return 'Screen Printing';
      }

      return 'Embroidery or Screen Printing';
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
